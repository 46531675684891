import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { menuItems } from '../../constants/routes';
import './NavMobileMenu.scss';

export const NavMobileMenu = () => {
  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  const handleItemClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'unset';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [open]);

  return (
    <>
      <div className={`burger ${open ? 'open' : ''}`} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </div>
      <ul className={`nav-menu ${open ? 'open' : ''}`}>
        <div>
          {menuItems.map(({ SubMenuComponent, subMenu, ...item }, index) => (
            <li className='navigation-item' key={index} onClick={() => handleItemClick(index)}>
              <span>
                {subMenu ? (
                  <span className='navigation-item-link'>
                    <span>{item.title}</span>
                    <span
                      className={`navigation-item-arrow ${activeIndex === index ? 'active' : ''}`}
                    />
                    <ul className={`sub-menu ${activeIndex === index ? 'active' : ''}`}>
                      {subMenu.map((subItem, index) => (
                        <li key={index}>
                          <Link to={subItem.link} onClick={() => setOpen(false)}>
                            {subItem.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </span>
                ) : (
                  <Link
                    className='navigation-item-link'
                    to={item.link}
                    onClick={() => setOpen(false)}
                  >
                    <span>{item.title}</span>
                  </Link>
                )}
              </span>
            </li>
          ))}
        </div>
      </ul>
    </>
  );
};
