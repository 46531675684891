import './RepairProcess.scss';

const data = [
  {
    title: 'Оформление заказа на ремонт в сервисном центре',
  },
  {
    title: 'Диагностика, согласование стоимости и сроков',
  },
  {
    title: 'Выполнение ремонта устройства',
  },
  {
    title: 'SMS-уведомление или по телефону по готовности',
  },
  {
    title: 'Оплата за ремонт, получение документов',
  },
];

export const RepairProcess = () => {
  return (
    <div className='repair-process'>
      <div className='repair-process-steps'>
        <div className='content-section'>
          <h3 className='repair-process-header'>Процесс ремонта</h3>
          <div className='repair-process-steps-list'>
            {data.map((item, index) => {
              return (
                <div className='repair-process-step' key={index}>
                  <span className='step-number'>{index + 1}</span>
                  <span>{item.title}</span>
                  <div className='repair-process-step__line' />
                </div>
              );
            })}
          </div>
        </div>
        <div className='repair-process-background' />
      </div>
    </div>
  );
};
