import './Devices.scss';
import MainBanner from '../../components/MainBanner';
import phoneImg from '../../assets/images/phone.png';
import DevicesBlock from '../../components/DevicesBlock';
import useBreakpoint from 'use-breakpoint';
import { BREAKPOINTS } from '../../constants/breakpoints';
import { Map } from '../../components/Map/Map';

const settingsByBrand = (brandName) => {
  return {
    header: `РЕМОНТ ТЕХНИКИ ${brandName.toUpperCase()}`,
    img: phoneImg,
    desc: `Сломалась техника ${brandName.toUpperCase()}? Звоните! <br />
            Диагностика бесплатно. Ремонт без очереди <br />
            Гарантия на работу и запчасти. Любые способы оплаты.`,
  };
};

export const Devices = ({ brand }) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const { header, img, desc } = settingsByBrand(brand);

  return (
    <div className='section'>
      <div className='content-section'>
        <MainBanner header={header} img={img} text={desc} />
      </div>
      <div className='content-section'>
        <DevicesBlock brandLink={`/${brand}`} />
      </div>
      {breakpoint !== 'mobile' && <Map />}
    </div>
  );
};
