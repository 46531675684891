import { Route, Routes } from 'react-router-dom';

import Brand from '../../pages/Brand';
import MainBanner from '../../components/MainBanner';
import BrandBlock from '../../components/BrandBlock';
import { brandsByDeviceType } from '../../constants/brands';
import phoneBannerIcon from '../../assets/images/phone.png';
import './Phones.scss';

export const Phones = () => {
  return (
    <div className='section'>
      <div className='content-section'>
        <MainBanner
          header='РЕМОНТ ТЕЛЕФОНОВ'
          text={`Сломался телефон? Звоните! Ремонт за 1 час! <br />
            Диагностика всегда бесплатно. Бесплатная парковка. <br />
            Гарантия на работу и запчасти. Любые способы оплаты.`}
          img={phoneBannerIcon}
        />
      </div>
      <div className='content-section'>
        <BrandBlock
          header='Выберите свой бренд телефона'
          baseUrlPath='/phones/'
          items={brandsByDeviceType.phones.brands}
        />
      </div>
      <Routes>
        <Route path='/phones/:brand' element={<Brand />} />
      </Routes>
    </div>
  );
};
