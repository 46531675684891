// replace 'YOUR_TELEGRAM_BOT_TOKEN' with your actual bot token

const TELEGRAM_TOKEN = '6239595517:AAE4pHmw9mXfNB7iJiLLSCOzviheUtYq9Uk';

export const sendMessage = async (text) => {
  // roy
  await fetch(`https://api.telegram.org/bot${TELEGRAM_TOKEN}/sendMessage`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ chat_id: '135901079', parse_mode: 'Markdown', text }),
  });

  // alex
  await fetch(`https://api.telegram.org/bot${TELEGRAM_TOKEN}/sendMessage`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ chat_id: '281871906', parse_mode: 'Markdown', text }),
  });

  // test roy
  await fetch(`https://api.telegram.org/bot${TELEGRAM_TOKEN}/sendMessage`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ chat_id: '5580592848', parse_mode: 'Markdown', text }),
  });

  // fix my apple
  return await fetch(`https://api.telegram.org/bot${TELEGRAM_TOKEN}/sendMessage`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ chat_id: '6209483361', parse_mode: 'Markdown', text }),
  });
};
