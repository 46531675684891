import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  orderType: null,
  orderName: null,
};

export const orderFormSlice = createSlice({
  name: 'orderForm',
  initialState,
  reducers: {
    open: (state, { payload }) => {
      state.isOpen = true;
      state.orderType = payload.type;
      state.orderName = payload.orderName;
    },
    close: (state) => {
      state.isOpen = false;
      state.orderType = null;
      state.orderName = null;
    },
  },
});

export const { open, close } = orderFormSlice.actions;

export default orderFormSlice.reducer;
