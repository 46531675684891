import React from 'react';

import FeedbackBlock from '../../components/FeedbackBlock';
import './Feedbacks.scss';

export const Feedbacks = () => {
  return (
    <div className='feedback-page'>
      <div className='content-section'>
        <h1>Отзывы о сервисном центре &quot;FMA Service&quot;</h1>
      </div>
      <div className='content-section'>
        <FeedbackBlock />
      </div>
    </div>
  );
};
