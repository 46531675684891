import React from 'react';
import cn from 'classnames';
import tw from "twin.macro";

import './Notification.scss';
// import WarningIcon from  '../../../assets/icons/warning.png'

export const NotificationWrapper = tw.div`flex-row text-center bg-yellow-200 text-yellow-800`;
export const NotificationText = tw.div`m-auto`;
// export const NotificationIcon = tw.div`m-auto`;


export const Notification = ({ children, className, size, ...props }) => {
  return (
    <NotificationWrapper className={cn('notification')} {...props}>
      {/* <NotificationIcon> */}
      {/*  <img width={40} src={WarningIcon} alt="warning" /> */}
      {/* </NotificationIcon> */}
      <NotificationText>
        Внимание! Адрес нашего сервисного центра изменился! <br />
        Теперь мы находимся по адресу: <b>Москва, м. Студенческая, ул. Киевская 20</b>
      </NotificationText>
    </NotificationWrapper>
  );
};

Notification.displayName = "Notification";
