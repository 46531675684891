import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import Input from '../atoms/Input';
import Button from '../atoms/Button';
import Modal from '../Modal';
import { sendMessage } from '../../utils/telegram-bot';
import { close } from '../../redux/slices/orderFormSlice';
import './OrderForm.scss';

export const OrderForm = ({ children, className, type, ...props }) => {
  const dispatch = useDispatch();
  const { isOpen, orderName } = useSelector((state) => state.orderForm);

  console.log('orderName', orderName);
  const [isLoading, setLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    console.log(data);
    setLoading(true);
    await sendMessage(
      '*Новый лид*\n' +
        orderName +
        '\n\n*Имя:* ' +
        data.name +
        '\n*Телефон:* ' +
        data.phone +
        '\n*Комментарий:* ' +
        data.comment +
        '\n*Откуда:* [' +
        window.location.href +
        '](' +
        window.location.href +
        ')' +
        '\n*Промокод:* ' +
        data.promo,
    );
    setLoading(false);
    setSuccess(true);
  };

  const handleClose = () => {
    dispatch(close());
  };

  console.log(errors);

  return (
    <Modal onClose={handleClose} isModalVisible={isOpen}>
      {isLoading && (
        <div className='loader'>
          <div className='circle-loader' />
        </div>
      )}
      <div className='request-call-modal'>
        {isSuccess && <div>Спасибо за заявку! Мы рассмотрим ваш запрос в ближайшее время</div>}
        {!isSuccess && (
          <>
            <h2>Заказать звонок</h2>
            <p>
              Оставьте свои контактные данные и наши менджеры свяжутся с Вами в течение 15 минут для
              уточнения деталей заказа.
            </p>

            <form className='request-call-from' onSubmit={handleSubmit(onSubmit)}>
              <div className='input-container'>
                <Input type='text' placeholder='Ваше имя' {...register('name')} />
              </div>
              <div className='input-container'>
                <Input
                  type='text'
                  placeholder='Ваш телефон'
                  {...register('phone', { required: true })}
                />
                {errors.phone && <span className='error'>Необходимо ввести номер телефона</span>}
              </div>
              <div className='input-container'>
                <Input type='text' placeholder='Комментарий' {...register('comment')} />
              </div>
              <div className='input-container'>
                <Input type='text' placeholder='Промокод' {...register('promo')} />
              </div>
              <div className='button-container'>
                <Button size='small' type='submit' onPress={handleSubmit(onSubmit)}>
                  Отправить
                </Button>
              </div>
            </form>
          </>
        )}
      </div>
    </Modal>
  );
};
