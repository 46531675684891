import React from 'react';
import useBreakpoint from 'use-breakpoint';

import NavMenu from '../NavMenu';
import SocialLinks from '../SocialLinks';
import NavMobileMenu from '../NavMobileMenu';
// import { ReactComponent as LogoIcon } from '../../assets/logo.svg';
// import { ReactComponent as OldLogo } from '../../assets/old-logo.svg';
import logoOld from '../../assets/images/logo-old.jpeg';
import { ReactComponent as PhoneIcon } from '../../assets/icons/Phone.svg';
import { ReactComponent as LocationIcon } from '../../assets/icons/Location.svg';
import { ReactComponent as MailIcon } from '../../assets/icons/Mail.svg';
import { QuestionIcon, PhoneIcon as PhoneIconV2 } from '../../assets/icons';
import { BREAKPOINTS } from '../../constants/breakpoints';
import './Header.scss';
import Button from '../atoms/Button';

export const Header = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  return (
    <header className='header'>
      <div className='header-wrapper'>
        <div className='header-menu'>
          <div className='header-menu-column header-logo'>
            <a href='/' className='logo-link'>
              {/* <OldLogo /> */}
              <img src={logoOld} alt="" />
              <span className='logo-subtitle'>СЕРВИСНЫЙ ЦЕНТР</span>
            </a>
          </div>
          <div className='header-menu-column header-address'>
            <LocationIcon />
            <div className='header-address-text'>
              <span>Москва, м. Студенческая,</span>
              <span>ул. Киевская 20</span>
              <div className='header-work-time'><b>Пн-Пт</b> 10:00-20:00, <b>Сб</b> 10:00-19:00, <b>Вс</b> выходной</div>
            </div>
          </div>
          <div className='header-menu-column header-contacts-wrapper'>
            <div className='header-contacts'>
              <div className='header-contact'>
                <a href='tel:+74951779897' className='header-contact-link'>
                  <PhoneIcon /> +7 (495) 177-98-97
                </a>
              </div>
              <div className='header-contact'>
                <a href='mailto:i@ffixmyapple.ru' className='header-contact-link'>
                  <MailIcon /> i@ffixmyapple.ru
                </a>
              </div>
            </div>
            <div className='header-social'>
              <SocialLinks />
            </div>
            <Button size='small' onClick={() => window.__statusWidget.open()}>
              Проверить статус заказа
            </Button>
          </div>
          {breakpoint === 'mobile' && (
            <div className='header-social-mobile'>
              <QuestionIcon onClick={() => window.__statusWidget.open()} className='info-button' />
              <a href='tel:+74951779897' className='header-contact-link'>
                <PhoneIconV2 className='info-button' />
              </a>
              <SocialLinks />
            </div>
          )}
        </div>
      </div>
      {breakpoint === 'mobile' ? <NavMobileMenu /> : <NavMenu />}
    </header>
  );
};
