import './Tablets.scss';
import MainBanner from '../../components/MainBanner';
import phoneBannerIcon from '../../assets/images/tablet.png';
import BrandBlock from '../../components/BrandBlock';
import { brandsByDeviceType } from '../../constants/brands';

export const Tablets = () => {
  return (
    <div className='section'>
      <div className='content-section'>
        <MainBanner
          header='РЕМОНТ ПЛАНШЕТОВ'
          text={`Сломался планшет? Звоните! Ремонт за 1 час! <br />
            Диагностика всегда бесплатно. Бесплатная парковка. <br />
            Гарантия на работу и запчасти. Любые способы оплаты.`}
          img={phoneBannerIcon}
        />
      </div>
      <div className='content-section'>
        <BrandBlock items={brandsByDeviceType.tablets.brands} baseUrlPath='/tablets/' />
      </div>
    </div>
  );
};
