import React from 'react';

import './SocialLinks.scss';

import { ReactComponent as TelegramIcon } from '../../assets/icons/Telegram.svg';
import { ReactComponent as WhatsAppIcon } from '../../assets/icons/WhatsApp.svg';

export const SocialLinks = () => {
  return (
    <div className='social-links'>
      <a href='https://t.me/+79152471415' className='social-link'>
        <TelegramIcon />
      </a>
      <a href='https://wa.me/79152471415' className='social-link'>
        <WhatsAppIcon />
      </a>
    </div>
  );
};
