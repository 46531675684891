import './FeedbackBlock.scss'
export const FeedbackBlock = () => {
  return (
    <div className="feedback-block">
      <iframe style={{
        width: 760,
        height: '100%',
        border: '1px solid #e6e6e6',
        borderRadius: '8px',
        boxSizing: 'border-box',
      }}
              src="https://yandex.ru/maps-reviews-widget/184772866831?comments" />
      <a href="https://yandex.ru/maps/org/remont_apple_fixmyapple/184772866831/" target="_blank"
         style={{
           boxSizing: 'border-box',
           textDecoration: 'none',
           color: '#b3b3b3',
           fontSize: '10px',
           fontFamily: 'YS Text, sans-serif',
           padding: '0 20px',
           position: 'absolute',
           bottom: '8px',
           width: '100%',
           textAlign: 'center',
           left: '0',
           overflow: 'hidden',
           textOverflow: 'ellipsis',
           display: 'block',
           maxHeight: '14px',
           whiteSpace: 'nowrap',
           paddingLeft: '16px',
           paddingRight: '16px',
         }}
         rel="noreferrer">Ремонт Apple FixMyApple на карте Москвы — Яндекс Карты</a>
    </div>
  );
};