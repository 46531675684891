import { Outlet } from 'react-router-dom';

import Header from '../Header';
import Footer from '../Footer';
import ScrollToTop from '../ScrollToTop';

import './Layout.scss';
export const Layout = () => {
  return (
    <>
      <Header />
      <div className='main'>
        <Outlet />
      </div>
      <Footer />
      <ScrollToTop />
    </>
  );
};
