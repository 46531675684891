import Button from '../atoms/Button';
import OrderButton from '../OrderButton';
import './MainBanner.scss';
export const MainBanner = ({ children, className, caption, header, text, img, ...props }) => {
  return (
    <div className='main-banner'>
      <div className='main-banner-left-column'>
        <div className='main-banner-text-block'>
          <span className='main-banner-caption'>{caption}</span>
          <h1 className='main-banner-header'>{header}</h1>
          <p className='main-banner-text' dangerouslySetInnerHTML={{ __html: text }} />
        </div>
        <div className='main-banner-btn-desktop-block'>
          <OrderButton type='main-banner' orderName='Заказ обратного звонка с основного баннера'>
            <Button size='big'>Запросить ремонт</Button>
          </OrderButton>
        </div>
      </div>
      <div className='main-banner-image'>
        <img src={img} />
      </div>
      <div className='main-banner-btn-mobile-block'>
        <OrderButton type='main-banner' orderName='Заказ обратного звонка с основного баннера'>
          <Button size='big'>Запросить ремонт</Button>
        </OrderButton>
      </div>
    </div>
  );
};
