import { Link } from 'react-router-dom';
import { allBrands } from '../../constants/brands';

import './BrandBlock.scss';

export const BrandBlock = ({
  children,
  className,
  items,
  header = 'Выберите бренд для ремонта',
  baseUrlPath,
  ...props
}) => {
  return (
    <>
      <h2>{header}</h2>
      <div className='brand-block-items'>
        {items.map(({ slug, name, image }, index) => {
          const IconComponent = allBrands.find((brand) => brand.slug === slug)?.IconComponent;
          return (
            <div className='brand-block-item' key={index}>
              <Link className='brand-block-item-link' to={`${baseUrlPath}${slug}`}>
                <div className='brand-block-item-image'>{IconComponent && <IconComponent />}</div>
              </Link>
            </div>
          );
        })}
      </div>
    </>
  );
};
