import { allBrands } from './brands';
import { Submenu } from '../components/NavMenu/component/Submenu/Submenu';

export const routes = {
  HOME: '/',
  ABOUT: '/about',
  CONTACTS: '/contacts',
  FEEDBACKS: '/feedbacks',
};

export const menuItems = [
  {
    title: 'Производители',
    link: '#',
    subMenu: allBrands,
    SubMenuComponent: Submenu,
  },
  {
    title: 'Категории товаров',
    link: '#',
    subMenu: [
      { title: 'Телефоны', link: '/phones' },
      { title: 'Ноутбуки', link: '/laptops' },
      { title: 'Планшеты', link: '/tablets' },
      { title: 'Умные часы', link: '/watches' },
    ],
    SubMenuComponent: Submenu,
  },
  {
    title: 'О компании',
    link: '/info',
  },
  {
    title: 'Контакты',
    link: '/contacts',
  },
  {
    title: 'Отзывы',
    link: '/feedbacks',
  },
];
