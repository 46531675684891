import { configureStore } from '@reduxjs/toolkit';

import brandSlice from './slices/brandSlice';
import orderFormSlice from './slices/orderFormSlice';

export const store = configureStore({
  reducer: {
    brands: brandSlice,
    orderForm: orderFormSlice,
  },
});
