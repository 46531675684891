import './Watches.scss';
import MainBanner from '../../components/MainBanner';
import phoneBannerIcon from '../../assets/images/watches.png';
import BrandBlock from '../../components/BrandBlock';
import { brandsByDeviceType } from '../../constants/brands';

export const Watches = () => {
  return (
    <div className='section'>
      <div className='content-section'>
        <MainBanner
          header='РЕМОНТ УМНЫХ ЧАСОВ'
          text={`Сломались часы? Звоните! Ремонт за 1 час! <br />
            Диагностика всегда бесплатно. Бесплатная парковка. <br />
            Гарантия на работу и запчасти. Любые способы оплаты.`}
          img={phoneBannerIcon}
        />
      </div>
      <div className='content-section'>
        <BrandBlock items={brandsByDeviceType.watches.brands} baseUrlPath='/watches/' />
      </div>
    </div>
  );
};
