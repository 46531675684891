import './Laptops.scss';
import MainBanner from '../../components/MainBanner';
import phoneBannerIcon from '../../assets/images/laptop.png';
import BrandBlock from '../../components/BrandBlock';
import { brandsByDeviceType } from '../../constants/brands';

export const Laptops = () => {
  return (
    <div className='section'>
      <div className='content-section'>
        <MainBanner
          header='РЕМОНТ НОУТБУКОВ'
          text={`Сломался ноутбук? Звоните! Ремонт за 1 час! <br />
            Диагностика всегда бесплатно. Бесплатная парковка. <br />
            Гарантия на работу и запчасти. Любые способы оплаты.`}
          img={phoneBannerIcon}
        />
      </div>
      <div className='content-section'>
        <BrandBlock items={brandsByDeviceType.laptops.brands} baseUrlPath='/laptops/' />
      </div>
    </div>
  );
};
