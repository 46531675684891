import { cloneElement } from 'react';
import { useDispatch } from 'react-redux';

import './OrderButton.scss';
import { open } from '../../redux/slices/orderFormSlice';

export const OrderButton = ({ children, className, type, orderName, ...props }) => {
  const dispatch = useDispatch();
  const handleOpen = () => {
    dispatch(
      open({
        type,
        orderName,
      }),
    );
  };

  return cloneElement(children, { onClick: () => handleOpen() });
};
