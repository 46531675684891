import React from 'react';

import { CloseIcon } from '../../assets/icons';
import './Modal.scss';

export const Modal = ({ isModalVisible, children, onClose }) => (
  <div className={`modal ${isModalVisible ? 'open' : ''}`} onClick={onClose}>
    <div
      className={`modal-content ${isModalVisible ? 'open' : ''}`}
      onClick={(e) => e.stopPropagation()}
    >
      <span className='close' onClick={onClose}>
        <CloseIcon />
      </span>
      {children}
    </div>
  </div>
);
