import { Link } from 'react-router-dom';

import laptopImg from '../../assets/images/laptop.png';
import tabletImg from '../../assets/images/tablet.png';
import phoneImg from '../../assets/images/phone.png';
import watchImg from '../../assets/images/watches.png';
import './DevicesBlock.scss';

const data = [
  {
    title: 'Телефоны',
    price: 'от 1 200 руб',
    image: phoneImg,
    link: '/phones',
  },
  {
    title: 'Планшеты',
    price: 'от 1 200 руб',
    image: tabletImg,
    link: '/tablets',
  },
  {
    title: 'Ноутбуки',
    price: 'от 1 200 руб',
    image: laptopImg,
    link: '/laptops',
  },
  {
    title: 'Часы',
    price: 'от 1 200 руб',
    image: watchImg,
    link: '/watches',
  },
];
export const DevicesBlock = ({ children, className, brandLink, ...props }) => {
  return (
    <>
      <h2>Выберите устройство для ремонта</h2>
      <div className='device-block-items'>
        {data.map((item, index) => (
          <Link
            className='device-block-item'
            to={brandLink ? item.link + brandLink : item.link}
            key={index}
          >
            <div className='device-block-item-text'>
              <h3 className='device-block-item-text-title'>{item.title}</h3>
              <p className='device-block-item-text-price'>{item.price}</p>
            </div>
            <div className='device-block-item-image'>
              <div className='img-background' />
              <img src={item.image} />
            </div>
          </Link>
        ))}
      </div>
    </>
  );
};
