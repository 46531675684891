import React from 'react';

import './PaymentMethodList.scss';

import sber from './assets/sber.svg';
import mir from './assets/mir.svg';

export const PaymentMethodList = () => {
  return (
    <ul className='payment-method-list'>
      <li className='payment-method-item'>
        <img src={sber} alt='SBER' />
      </li>
      <li className='payment-method-item'>
        <img src={mir} alt='MIR' />
      </li>
    </ul>
  );
};
