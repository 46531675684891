import * as Icons from '../assets/icons/brands';

export const data = [
  {
    title: 'Телефоны',
    slug: 'phones',
    brands: [
      {
        title: 'apple',
        IconComponent: Icons.AppleIcon,
      },
      {
        title: 'sony',
        IconComponent: Icons.SonyIcon,
      },
      {
        title: 'samsung',
        IconComponent: Icons.SamsungIcon,
      },
      {
        title: 'xiaomi',
        IconComponent: Icons.XiaomiIcon,
      },
      {
        title: 'huawei',
        IconComponent: Icons.HuaweiIcon,
      },
      {
        title: 'honor',
        IconComponent: Icons.HonorIcon,
      },
      {
        title: 'meizu',
        IconComponent: Icons.MeizuIcon,
      },
      {
        title: 'lenovo',
        IconComponent: Icons.LenovoIcon,
      },
      {
        title: 'acer',
        IconComponent: Icons.AcerIcon,
      },
    ],
  },
  {
    title: 'Ноутбуки',
    slug: 'laptops',
    brands: [
      {
        title: 'apple',
        IconComponent: Icons.AppleIcon,
      },
      {
        title: 'sony',
        IconComponent: Icons.SonyIcon,
      },
      {
        title: 'xiaomi',
        IconComponent: Icons.XiaomiIcon,
      },
      {
        title: 'huawei',
        IconComponent: Icons.HuaweiIcon,
      },
      {
        title: 'honor',
        IconComponent: Icons.HonorIcon,
      },
      {
        title: 'lenovo',
        IconComponent: Icons.LenovoIcon,
      },
      {
        title: 'hp',
        IconComponent: Icons.HpIcon,
      },
      {
        title: 'dell',
        IconComponent: Icons.DellIcon,
      },
      {
        title: 'asus',
        IconComponent: Icons.AsusIcon,
      },
      {
        title: 'acer',
        IconComponent: Icons.AcerIcon,
      },
    ],
  },
  {
    title: 'Планшеты',
    slug: 'tablets',
    brands: [
      {
        title: 'apple',
        IconComponent: Icons.AppleIcon,
      },
      {
        title: 'sony',
        IconComponent: Icons.SonyIcon,
      },
      {
        title: 'samsung',
        IconComponent: Icons.SamsungIcon,
      },
      {
        title: 'huawei',
        IconComponent: Icons.HuaweiIcon,
      },
      {
        title: 'honor',
        IconComponent: Icons.HonorIcon,
      },
      {
        title: 'lenovo',
        IconComponent: Icons.LenovoIcon,
      },
      {
        title: 'acer',
        IconComponent: Icons.AcerIcon,
      },
    ],
  },
  {
    title: 'Умные часы',
    slug: 'watches',
    brands: [
      {
        title: 'apple',
        IconComponent: Icons.AppleIcon,
      },
      {
        title: 'sony',
        IconComponent: Icons.SonyIcon,
      },
      {
        title: 'samsung',
        IconComponent: Icons.SamsungIcon,
      },
      {
        title: 'xiaomi',
        IconComponent: Icons.XiaomiIcon,
      },
    ],
  },
];

export const allBrands = [
  {
    title: 'apple',
    slug: 'apple',
    IconComponent: Icons.AppleIcon,
    link: '/apple',
  },
  {
    title: 'sony',
    slug: 'sony',
    IconComponent: Icons.SonyIcon,
    link: '/sony',
  },
  {
    title: 'samsung',
    slug: 'samsung',
    IconComponent: Icons.SamsungIcon,
    link: '/samsung',
  },
  {
    title: 'xiaomi',
    slug: 'xiaomi',
    IconComponent: Icons.XiaomiIcon,
    link: '/xiaomi',
  },
  {
    title: 'huawei',
    slug: 'huawei',
    IconComponent: Icons.HuaweiIcon,
    link: '/huawei',
  },
  {
    title: 'honor',
    slug: 'honor',
    IconComponent: Icons.HonorIcon,
    link: '/honor',
  },
  {
    title: 'meizu',
    slug: 'meizu',
    IconComponent: Icons.MeizuIcon,
    link: '/meizu',
  },
  {
    title: 'lenovo',
    slug: 'lenovo',
    IconComponent: Icons.LenovoIcon,
    link: '/lenovo',
  },
  {
    title: 'hp',
    slug: 'hp',
    IconComponent: Icons.HpIcon,
    link: '/hp',
  },
  {
    title: 'dell',
    slug: 'dell',
    IconComponent: Icons.DellIcon,
    link: '/dell',
  },
  {
    title: 'asus',
    slug: 'asus',
    IconComponent: Icons.AsusIcon,
    link: '/asus',
  },
  {
    title: 'acer',
    slug: 'acer',
    IconComponent: Icons.AcerIcon,
    link: '/acer',
  },
];

export const brandsByDeviceType = {
  phones: {
    title: 'Телефоны',
    slug: 'phones',
    brands: [
      {
        title: 'apple',
        slug: 'apple',
        IconComponent: Icons.AppleIcon,
        link: '/phones/apple',
        models: [
          {
            title: 'iphone 11',
            slug: 'iphone-11',
            link: '/phones/apple/iphone-11',
          },
          {
            title: 'iphone 11 pro',
            slug: 'iphone-11-pro',
            link: '/phones/apple/iphone-11-pro',
          },
          {
            title: 'iphone 11 pro max',
            slug: 'iphone-11-pro-max',
            link: '/phones/apple/iphone-11-pro-max',
          },
          {
            title: 'iphone xs',
            slug: 'iphone-xs',
            link: '/phones/apple/iphone-xs',
          },
          {
            title: 'iphone xs max',
            slug: 'iphone-xs-max',
            link: '/phones/apple/iphone-xs-max',
          },
          {
            title: 'iphone xr',
            slug: 'iphone-xr',
            link: '/phones/apple/iphone-xr',
          },
          {
            title: 'iphone x',
            slug: 'iphone-x',
            link: '/phones/apple/iphone-x',
          },
        ],
      },
      {
        title: 'sony',
        slug: 'sony',
        IconComponent: Icons.SonyIcon,
        link: '/phones/sony',
        models: [
          {
            title: 'xperia 1',
            slug: 'xperia-1',
            link: '/phones/sony/xperia-1',
          },
          {
            title: 'xperia 5',
            slug: 'xperia-5',
            link: '/phones/sony/xperia-5',
          },
          {
            title: 'xperia 10',
            slug: 'xperia-10',
            link: '/phones/sony/xperia-10',
          },
          {
            title: 'xperia 10 plus',
            slug: 'xperia-10-plus',
            link: '/phones/sony/xperia-10-plus',
          },
          {
            title: 'xperia l3',
            slug: 'xperia-l3',
            link: '/phones/sony/xperia-l3',
          },
          {
            title: 'xperia xa2',
            slug: 'xperia-xa2',
            link: '/phones/sony/xperia-xa2',
          },
          {
            title: 'xperia xa2 ultra',
            slug: 'xperia-xa2-ultra',
            link: '/phones/sony/xperia-xa2-ultra',
          },
          {
            title: 'xperia xz3',
            slug: 'xperia-xz3',
            link: '/phones/sony/xperia-xz3',
          },
        ],
      },
      {
        title: 'samsung',
        slug: 'samsung',
        IconComponent: Icons.SamsungIcon,
        link: '/phones/samsung',
        models: [],
      },
      {
        title: 'xiaomi',
        slug: 'xiaomi',
        IconComponent: Icons.XiaomiIcon,
        link: '/phones/xiaomi',
        models: [],
      },
      {
        title: 'huawei',
        slug: 'huawei',
        IconComponent: Icons.HuaweiIcon,
        link: '/phones/huawei',
        models: [],
      },
      {
        title: 'honor',
        slug: 'honor',
        IconComponent: Icons.HonorIcon,
        link: '/phones/honor',
        models: [],
      },
      {
        title: 'meizu',
        slug: 'meizu',
        IconComponent: Icons.MeizuIcon,
        link: '/phones/meizu',
        models: [],
      },
      {
        title: 'lenovo',
        slug: 'lenovo',
        IconComponent: Icons.LenovoIcon,
        link: '/phones/lenovo',
        models: [],
      },
      {
        title: 'acer',
        slug: 'acer',
        IconComponent: Icons.AcerIcon,
        link: '/phones/acer',
        models: [],
      },
    ],
  },
  laptops: {
    title: 'Ноутбуки',
    slug: 'laptops',
    brands: [
      {
        title: 'apple',
        slug: 'apple',
        IconComponent: Icons.AppleIcon,
        link: '/laptops/apple',
        models: [
          {
            title: 'macbook pro',
            link: '/laptops/apple/macbook-pro',
          },
          {
            title: 'macbook air',
            link: '/laptops/apple/macbook-air',
          },
          {
            title: 'macbook',
            link: '/laptops/apple/macbook',
          },
        ],
      },
      {
        title: 'sony',
        slug: 'sony',
        IconComponent: Icons.SonyIcon,
        link: '/laptops/sony',
        models: [
          {
            title: 'vaio',
            link: '/laptops/sony/vaio',
          },
        ],
      },
      {
        title: 'xiaomi',
        slug: 'xiaomi',
        IconComponent: Icons.XiaomiIcon,
        link: '/laptops/xiaomi',
        models: [
          {
            title: 'mi notebook',
            link: '/laptops/xiaomi/mi-notebook',
          },
        ],
      },
      {
        title: 'huawei',
        slug: 'huawei',
        IconComponent: Icons.HuaweiIcon,
        link: '/laptops/huawei',
        models: [
          {
            title: 'matebook',
            link: '/laptops/huawei/matebook',
          },
        ],
      },
      {
        title: 'honor',
        slug: 'honor',
        IconComponent: Icons.HonorIcon,
        link: '/laptops/honor',
        models: [
          {
            title: 'magicbook',
            link: '/laptops/honor/magicbook',
          },
        ],
      },
      {
        title: 'lenovo',
        slug: 'lenovo',
        IconComponent: Icons.LenovoIcon,
        link: '/laptops/lenovo',
        models: [
          {
            title: 'ideapad',
            link: '/laptops/lenovo/ideapad',
          },
          {
            title: 'thinkpad',
            link: '/laptops/lenovo/thinkpad',
          },
        ],
      },
      {
        title: 'hp',
        slug: 'hp',
        IconComponent: Icons.HpIcon,
        link: '/laptops/hp',
      },
      {
        title: 'dell',
        slug: 'dell',
        IconComponent: Icons.DellIcon,
        link: '/laptops/dell',
      },
      {
        title: 'asus',
        slug: 'asus',
        IconComponent: Icons.AsusIcon,
        link: '/laptops/asus',
      },
      {
        title: 'acer',
        slug: 'acer',
        IconComponent: Icons.AcerIcon,
        link: '/laptops/acer',
      },
    ],
  },
  tablets: {
    title: 'Планшеты',
    slug: 'tablets',
    brands: [
      {
        title: 'apple',
        slug: 'apple',
        IconComponent: Icons.AppleIcon,
        link: '/tablets/apple',
        models: [],
      },
      {
        title: 'sony',
        slug: 'sony',
        IconComponent: Icons.SonyIcon,
        link: '/tablets/sony',
        models: [],
      },
      {
        title: 'samsung',
        slug: 'samsung',
        IconComponent: Icons.SamsungIcon,
        link: '/tablets/samsung',
        models: [],
      },
      {
        title: 'huawei',
        slug: 'huawei',
        IconComponent: Icons.HuaweiIcon,
        link: '/tablets/huawei',
        models: [],
      },
      {
        title: 'honor',
        slug: 'honor',
        IconComponent: Icons.HonorIcon,
        link: '/tablets/honor',
        models: [],
      },
      {
        title: 'lenovo',
        slug: 'lenovo',
        IconComponent: Icons.LenovoIcon,
        link: '/tablets/lenovo',
        models: [],
      },
      {
        title: 'acer',
        slug: 'acer',
        IconComponent: Icons.AcerIcon,
        link: '/tablets/acer',
        models: [],
      },
    ],
  },
  watches: {
    title: 'Умные часы',
    slug: 'watches',
    brands: [
      {
        title: 'apple',
        slug: 'apple',
        IconComponent: Icons.AppleIcon,
        link: '/watches/apple',
        models: [],
      },
      {
        title: 'sony',
        slug: 'sony',
        IconComponent: Icons.SonyIcon,
        link: '/watches/sony',
        models: [],
      },
      {
        title: 'samsung',
        slug: 'samsung',
        IconComponent: Icons.SamsungIcon,
        link: '/watches/samsung',
        models: [],
      },
      {
        title: 'xiaomi',
        slug: 'xiaomi',
        IconComponent: Icons.XiaomiIcon,
        link: '/watches/xiaomi',
        models: [],
      },
    ],
  },
};
