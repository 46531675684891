import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [],
};

export const brandSlice = createSlice({
  name: 'brands',
  initialState,
  reducers: {
    load: (state, action) => {
      state.items = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { load } = brandSlice.actions;

export default brandSlice.reducer;
