import Contacts from '../Contacts';

import './Map.scss';
export const Map = () => {
  return (
    <div className='map-block'>
      <iframe
        src='https://yandex.ru/map-widget/v1/?um=constructor%3A3dd7e6ede4a76aa14a0f9ba8993b17c4c4c6820420d9480411bcaee97cd2d9e3&amp;source=constructor&scroll=false'
        width='100%'
        height='600'
        frameBorder='0'
        style={{ position: 'absolute', left: 0, top: 0 }}
      />

      <div className='map-content content-section content-section--without-margin'>
        <div className='contacts-block-wrapper'>
          <Contacts />
        </div>
      </div>
    </div>
  );
};
