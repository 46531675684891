import Button from '../atoms/Button';
import OrderButton from '../OrderButton';

import './HelpBanner.scss';
export const HelpBanner = ({ children, className, ...props }) => {
  return (
    <div className='help-banner'>
      <div className='help-banner-left-column'>
        <div className='help-banner-text-block'>
          <h1 className='help-banner-header'>Не можете найти подходящий вид ремонта?</h1>
          <p className='help-banner-text'>
            Профессиональный ремонт смартфонов, планшетов, ноутбуков <br /> любой сложности.
          </p>
        </div>
        <OrderButton type='help-block' orderName='Блок помощи'>
          <Button>Заказать звонок</Button>
        </OrderButton>
      </div>
      <div className='help-banner-right-column'>
        <div className='help-banner-image' />
      </div>
    </div>
  );
};
