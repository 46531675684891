import { Link } from 'react-router-dom';
import React from 'react';

import './NavMenu.scss';
import { menuItems } from '../../constants/routes';

export const NavMenu = () => {
  return (
    <nav className='navigation'>
      <div className='navigation-wrapper'>
        <ul className='navigation-list'>
          {menuItems.map(({ SubMenuComponent, ...item }, index) =>
            SubMenuComponent ? (
              <SubMenuComponent {...item} key={index} />
            ) : (
              <li className='navigation-item' key={index}>
                <span>
                  <Link className='navigation-item-link' to={item.link}>
                    {item.title}
                  </Link>
                </span>
              </li>
            ),
          )}
        </ul>
      </div>
    </nav>
  );
};
