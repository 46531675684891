import useBreakpoint from 'use-breakpoint';

import Button from '../atoms/Button';
import OrderButton from '../OrderButton';
import { BREAKPOINTS } from '../../constants/breakpoints';

import './PraceTable.scss';

export const PriceTable = ({ categoryTitle, services = [] }) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  return (
    <div className='price-table-block'>
      <div className='price-table-block-header'>
        <h3>Цены на ремонт {categoryTitle}</h3>
        <span className='price-table-block-under-header'>С учетом запчастей</span>
      </div>
      {breakpoint === 'mobile' ? (
        <div className='price-items-wrapper'>
          {services.map((item, index) => (
            <div className='price-item' key={index}>
              <div className='price-item-header bold'>{item.name}</div>
              <div className='price-item-option'>
                <span>Стоимость</span>
                <span>{item.price}</span>
              </div>
              <div className='price-item-option'>
                <span>Время</span>
                <span>{item.time}</span>
              </div>
              <div className='price-item-option'>
                <span>Гарантия</span>
                <span>{item.warranty || '-'}</span>
              </div>
              <div className='price-item-btn'>
                <OrderButton
                  type='buy-service'
                  orderName={`Заказ ремонта ${categoryTitle}, ${item.name}`}
                >
                  <Button size='small'>Заказать</Button>
                </OrderButton>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className='price-table'>
          <div className='price-table-header'>
            <div className='price-table-header-item'>Наименование услуги</div>
            <div className='price-table-header-item'>Стоимость</div>
            <div className='price-table-header-item'>Время</div>
            <div className='price-table-header-item'>Гарантия</div>
            <div className='price-table-header-item' />
          </div>
          {services.map((item, index) => (
            <div className='price-table-row' key={index}>
              <div className='price-table-row-item bold'>{item.name}</div>
              <div className='price-table-row-item bold'>{item.price}</div>
              <div className='price-table-row-item gray-text'>{item.time}</div>
              <div className='price-table-row-item gray-text'>{item.warranty || '-'}</div>
              <div className='price-table-row-item'>
                <OrderButton
                  type='buy-service'
                  orderName={`Заказ ремонта ${categoryTitle}, ${item.name}`}
                >
                  <Button size='small'>Заказать</Button>
                </OrderButton>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
