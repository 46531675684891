import React, { useState, useEffect } from 'react';
import { useAnimate } from 'framer-motion';
import { Link } from 'react-router-dom';

import './Submenu.scss';

const useMenuAnimation = (submenuOpen) => {
  const [scope, animate] = useAnimate();

  useEffect(() => {
    const menuAnimation = submenuOpen
      ? {
          // height: 'auto',
          display: 'block',
          opacity: 1,
          // clipPath: 'inset(0% 0% 0% 0% round 10px)',
          transition: {
            type: 'spring',
            bounce: 0,
            duration: 0.5,
          },
        }
      : {
          // height: 0,
          display: 'none',
          opacity: 0,
          // clipPath: 'inset(10% 50% 90% 50% round 10px)',
          transition: {
            type: 'spring',
            bounce: 0,
            duration: 0.5,
          },
        };
    animate('.navigation-submenu-wrapper', menuAnimation);
  }, [submenuOpen, animate]);

  return scope;
};
export const Submenu = ({ title, subMenu }) => {
  const [submenuOpen, setSubmenuOpen] = useState(false);

  const scope = useMenuAnimation(submenuOpen);

  return (
    <li
      className='navigation-item'
      ref={scope}
      onMouseEnter={() => setSubmenuOpen(true)}
      onMouseLeave={() => setSubmenuOpen(false)}
    >
      <a className='navigation-item-link' href='#'>
        {title}
      </a>
      <div className='navigation-submenu-wrapper'>
        <ul className='navigation-submenu'>
          {subMenu.map((item, index) => (
            <li className='navigation-submenu-item' key={index}>
              <Link to={item.link}>{item.title}</Link>
            </li>
          ))}
        </ul>
      </div>
    </li>
  );
};
