import { Link } from 'react-router-dom';

import './ModelsBlock.scss';

export const ModelsBlock = ({
  children,
  className,
  items,
  header = 'Выберите модель устройства',
  ...props
}) => {
  return (
    <div className='models-block'>
      <h2>{header}</h2>
      <div className='models-block-items'>
        {items.map(({ slug, name, IconComponent }, index) => (
          <div className='models-block-item' key={index}>
            <Link className='models-block-item-link' to={slug}>
              <div className='models-block-item-title'>{name}</div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};
